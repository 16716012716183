import React from "react";
import Layout from "../components/layout";
import SEO from "../components/Seo";
import { Link } from "gatsby";
import emm from "../assets/images/artists/emm/profile/emm-artist-profile.jpg";
import emm001 from "../assets/images/artists/emm/work/emm-001.jpg";
import emm002 from "../assets/images/artists/emm/work/emm-002.jpg";
import emm003 from "../assets/images/artists/emm/work/emm-003.jpg";
import emm004 from "../assets/images/artists/emm/work/emm-004.jpg";
import emm005 from "../assets/images/artists/emm/work/emm-005.jpg";
import emm006 from "../assets/images/artists/emm/work/emm-006.jpg";
import emm007 from "../assets/images/artists/emm/work/emm-007.jpg";
import emm008 from "../assets/images/artists/emm/work/emm-008.jpg";
import emm009 from "../assets/images/artists/emm/work/emm-009.jpg";
import emm010 from "../assets/images/artists/emm/work/emm-010.jpg";

import dmon from "../assets/images/artists/dmon/profile/dmon-artist-profile-medium.jpg";
// import emm from '../assets/images/artists/emm/profile/emm-artist-profi
import christos from "../assets/images/artists/christos/profile/christos-artist-profile-medium.jpg";

import jeanine from "../assets/images/artists/jeanine/profile/jeanine-artist-profile.jpeg";

import tony from "../assets/images/artists/tony/profile/tony-artist-profile.jpg";

const Artist_Emm = props => (
  <Layout>
    <SEO
      title="Artist Emily Kay"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />
    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Emily Kay (Emm)</h1>
        </header>
        <div className="image">
          <img src={emm} alt="emm" className="image left " />
          <p>
            Emily is an LA native who previously attended design school in New
            York. She started tattooing in 2016 after her apprenticeship under
            D’mon The Architect. She is heavily influenced by natural and
            western imagery and loves horseback riding.{" "}
          </p>
          <hr />
          <h4 style={{ float: `left` }}>
            <a
              href="https://www.instagram.com/emmbuns/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @emmbuns
            </a>
          </h4>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>

        <section
          className="tiles"
          style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
        >
          <article
            style={{
              backgroundImage: `url(${emm001})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm002})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm003})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm004})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm005})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm006})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm007})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm008})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm009})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${emm010})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`
            }}
          ></article>
        </section>
        <header className="major">
          <h1>More Artists</h1>
        </header>
        <section id="artists" className="tiles">
          <article style={{ backgroundImage: `url(${dmon})` }}>
            <header className="major">
              <h3>D'mon - The Architect</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-dmon" className="link primary"></Link>
          </article>

          <article style={{ backgroundImage: `url(${christos})` }}>
            <header className="major">
              <h3>Christos</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-christos" className="link primary"></Link>
          </article>

          <article style={{ backgroundImage: `url(${jeanine})` }}>
            <header className="major">
              <h3>Jeanine</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-jeanine" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${tony})` }}>
            <header className="major">
              <h3>Tony</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-tony" className="link primary"></Link>
          </article>
        </section>
      </div>
    </div>
  </Layout>
);

// export const query = graphql`
// query ArtistEmm {
//     allInstaNode (limit: 15) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `
export default Artist_Emm;
